import React, { lazy, useEffect, useState,useReducer } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import checkAuth from './app/auth';
import initializeApp from './app/init';
import {getData} from './callApi/backend_api'
import Context from './context/authenticate';
import {reducer, initialState} from './context/Reducer'
import { ErrorMessage, SuccessMessage } from './Message';
// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const Register = lazy(() => import('./pages/Register'))
const Documentation = lazy(() => import('./pages/Documentation'))


// Initializing different libraries
initializeApp()


// Check for login and initialize axios
const token = checkAuth()


function App() {
  const [isLoggedIn,setIsLoggedIn]=useState(null);
  const [admin,setAdmin]=useState(false);
  const [messages,dispatch]=useReducer(reducer,initialState)
  const [openWait,setOpenWait]=useState(false);
  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false)
  }, [])

 useEffect(()=>{
    getData('/auth/isLoggedIn').then((res)=>{
      console.log(res)
      if(res){
          setIsLoggedIn(true)
          setAdmin(true)
          // window.location.href='/app/dashboard'
          // setRadius(res.data.radius)
      }
        else{
          setIsLoggedIn(false);
        }
      
      
    })
  },[])
  console.log(localStorage.getItem('access_token'))
  return (
    <>
     <Context.Provider value={{status:dispatch,wait:setOpenWait,admin,setAdmin,isLoggedIn,setIsLoggedIn}}>
     <SuccessMessage open={messages.success} message={messages.message}/>
      <ErrorMessage open={messages.error} message={messages.message}/>       
   
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} /> */}

          
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route path="*" element={<Navigate to={"/app/dashboard" } replace />}/>

        </Routes>
      </Router>
      </Context.Provider>
    </>
  )
}

export default App
